import React from 'react';
import PropTypes from 'prop-types';
import QuotesIcon from '../../assets/icons/quotes.svg';

function TestimonialSectionItem({ name, description, logoName, position, logoAlt }) {
    return (
        <div>
            <div className="testimonial-section__item">
                <div className="testimonial-section__quotes-icon">
                    <img src={QuotesIcon} alt="" aria-hidden="true" />
                </div>
                <div className="testimonial-section__description">
                    <blockquote>
                        {description}
                    </blockquote>
                </div>
                {(name || position) && <div className="testimonial-section__signature">
                    {name && <div className="testimonial-section__signature-name">
                        {name}
                    </div>}
                    {position && <div className="testimonial-section__signature-position">
                        {position}
                    </div>}
                </div>}
                {logoName && <div className="testimonial-section__logo-wrapper">
                    <img src={logoName} alt={logoAlt} />
                </div>}
            </div>
            <hr className="testimonial-section__divider" />
        </div>
    );
}

TestimonialSectionItem.propTypes = {
    name: PropTypes.string,
    description: PropTypes.string.isRequired,
    logoName: PropTypes.string,
    position: PropTypes.string,
    logoAlt: PropTypes.string
};

export default TestimonialSectionItem;